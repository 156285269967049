import React from "react";
import Photo from "../../assets/img/Baumann.png";

const AboutContent = () => {
  return (
    <div id="about" className="block">
      <h2>
        <span className="line">Effective,</span>
        <span className="line">visually appealing</span>
        <br /> data visualizations and websites
      </h2>
      <h3>Effective, visually appealing data vis and websites</h3>

      <div className="row bg-half-ring-left gutter-width-lg">
        <div className="col col-image align-self-top pl-0">
          <div className="img object-fit">
            <div className="object-fit-cover">
              <img
                src={Photo}
                alt="Person Kristin Baumann. Black and white. Frontal facing. Smiling"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="col col-text align-self-center description">
          <h4>Hi, I’m Kristin Baumann.</h4>

          <p>
            In my freelance work, I combine relevant and meaningful data with
            problem-focused yet appealing design and state-of-the-art
            programming to create effective data visualizations and websites.
          </p>
          <p>
            After finishing my Computer Science degree, I worked in data, dev,
            design, and product teams around the world taking on all the
            different perspectives of a developer, designer, product manager,
            data analyst, user researcher, and team lead.
          </p>
          <p>
            This equipped me with a proactive and collaborative mindset, a high
            appreciation for reliable communication, and a focus on solving
            problems and creating value.
          </p>
          <p>
            Occasionally I share my experiences in conference or meetup talks.
          </p>
          <a
            className="btn btn-outline-dark transform-scale-h"
            href="https://linkedin.com/in/kristinbaumann"
            target="_blank"
            rel="noopener noreferrer"
          >
            See LinkedIn for full resume
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
